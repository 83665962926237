var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-footer',{attrs:{"id":"home-footer","color":_vm.strBackgroundColor}},[_c('v-container',{staticClass:"white--text mt-50"},[_c('v-row',[_c('v-col',{class:`text-${_vm.g_bLowerBr ? 'center' : 'left'} py-0`,style:(_vm.g_bLowerBr && {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }),attrs:{"cols":"12","lg":`${_vm.g_bHighestBr ? 8 : _vm.g_bLowerBr ? 12 : 6}`,"md":`${_vm.g_bHighestBr ? 8 : _vm.g_bLowerBr ? 12 : 6}`}},[_c('v-img',{staticClass:"mt-1",attrs:{"src":require('@/assets/logos/logo-kedatech-white.png'),"contain":"","position":"left","max-height":"92","width":"fit-content"}}),_c('br'),_c('div',{class:`kda-ts-${_vm.g_bLowerBr ? 14 : 20}pt nunito wt-regular`,style:({ width: _vm.g_bLowerBr ? '70%' : ' auto' }),domProps:{"innerHTML":_vm._s(_vm.m_htmlAddress)}}),(_vm.g_bLowerBr)?_c('div',{staticStyle:{"border":"1px solid white","width":"100%","margin":"25px 0px"}}):_vm._e()],1),_c('v-col',{class:`kda-ts-${_vm.g_bLowerBr ? 16 : 24}pt wt-extrabold `,style:({
          display: 'grid',
          height: 'fit-content',
          justifyContent: _vm.g_bLowerBr ? 'flex-start' : 'center'
        }),attrs:{"cols":`${_vm.g_bLowerBr ? 6 : 12}`,"lg":`${_vm.g_bHighestBr ? 2 : _vm.g_bLowerBr ? 6 : 3}`,"md":`${_vm.g_bHighestBr ? 2 : _vm.g_bLowerBr ? 6 : 3}`}},[_vm._v(" "+_vm._s(_vm.m_strQuickLinks)+" "),_vm._l((_vm.m_arrQuickLinks),function(item){return _c(item.strComponent,{key:item.strText,tag:"component",class:`textLink kda-ts-${_vm.g_bLowerBr ? 12 : 16}pt nunito wt-regular mt-2`,on:{"click":item.onClick}},[_vm._v(" "+_vm._s(item.strText)+" ")])})],2),_c('v-col',{class:`kda-ts-${_vm.g_bLowerBr ? 16 : 24}pt wt-extrabold `,style:({
          display: 'grid',
          height: 'fit-content',
          justifyContent: _vm.g_bLowerBr ? 'flex-start' : 'center'
        }),attrs:{"cols":`${_vm.g_bLowerBr ? 6 : 12}`,"lg":`${_vm.g_bHighestBr ? 2 : _vm.g_bLowerBr ? 6 : 3}`,"md":`${_vm.g_bHighestBr ? 2 : _vm.g_bLowerBr ? 6 : 3}`}},[_vm._v(" "+_vm._s(_vm.m_strFindUs)+" "),_vm._l((_vm.m_arrFindUs),function(item){return _c(item.strComponent,{key:item.strText,tag:"component",class:`textLink kda-ts-${_vm.g_bLowerBr ? 12 : 16}pt nunito wt-regular mt-2`,on:{"click":item.onClick}},[_vm._v(" "+_vm._s(item.strText)+" ")])})],2)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","lg":"12","md":"12"}},[_c('div',{class:`kda-ts-${_vm.g_bLowerBr ? 12 : 16}pt nunito wt-regular text-center textCopyright`,domProps:{"innerHTML":_vm._s(_vm.m_htmlCopyright)}})])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }