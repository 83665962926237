<template>
  <v-footer id="home-footer" :color="strBackgroundColor">
    <v-container class="white--text mt-50">
      <v-row>
        <v-col
          :class="`text-${g_bLowerBr ? 'center' : 'left'} py-0`"
          cols="12"
          :lg="`${g_bHighestBr ? 8 : g_bLowerBr ? 12 : 6}`"
          :md="`${g_bHighestBr ? 8 : g_bLowerBr ? 12 : 6}`"
          :style="
            g_bLowerBr && {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center'
            }
          "
        >
          <v-img
            :src="require('@/assets/logos/logo-kedatech-white.png')"
            contain
            position="left"
            class="mt-1"
            max-height="92"
            width="fit-content"
          />
          <br />
          <div
            :style="{ width: g_bLowerBr ? '70%' : ' auto' }"
            :class="`kda-ts-${g_bLowerBr ? 14 : 20}pt nunito wt-regular`"
            v-html="m_htmlAddress"
          />
          <div v-if="g_bLowerBr" style="border: 1px solid white; width: 100%; margin: 25px 0px" />
        </v-col>

        <v-col
          :cols="`${g_bLowerBr ? 6 : 12}`"
          :lg="`${g_bHighestBr ? 2 : g_bLowerBr ? 6 : 3}`"
          :md="`${g_bHighestBr ? 2 : g_bLowerBr ? 6 : 3}`"
          :style="{
            display: 'grid',
            height: 'fit-content',
            justifyContent: g_bLowerBr ? 'flex-start' : 'center'
          }"
          :class="`kda-ts-${g_bLowerBr ? 16 : 24}pt wt-extrabold `"
        >
          {{ m_strQuickLinks }}
          <component
            :is="item.strComponent"
            v-for="item in m_arrQuickLinks"
            :key="item.strText"
            :class="`textLink kda-ts-${g_bLowerBr ? 12 : 16}pt nunito wt-regular mt-2`"
            @click="item.onClick"
          >
            {{ item.strText }}
          </component>
        </v-col>
        <v-col
          :cols="`${g_bLowerBr ? 6 : 12}`"
          :lg="`${g_bHighestBr ? 2 : g_bLowerBr ? 6 : 3}`"
          :md="`${g_bHighestBr ? 2 : g_bLowerBr ? 6 : 3}`"
          :style="{
            display: 'grid',
            height: 'fit-content',
            justifyContent: g_bLowerBr ? 'flex-start' : 'center'
          }"
          :class="`kda-ts-${g_bLowerBr ? 16 : 24}pt wt-extrabold `"
        >
          {{ m_strFindUs }}
          <component
            :is="item.strComponent"
            v-for="item in m_arrFindUs"
            :key="item.strText"
            :class="`textLink kda-ts-${g_bLowerBr ? 12 : 16}pt nunito wt-regular mt-2`"
            @click="item.onClick"
          >
            {{ item.strText }}
          </component>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12" md="12">
          <div
            :class="`kda-ts-${g_bLowerBr ? 12 : 16}pt nunito wt-regular text-center textCopyright`"
            v-html="m_htmlCopyright"
          />
        </v-col>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
import BaseBreakpoint from '@/mixins/base-breakpoint';

export default {
  name: 'KDAMainFooter',

  mixins: [BaseBreakpoint],

  props: {
    strBackgroundColor: {
      type: String,
      default: 'main-bw-500'
    }
  },

  data: () => ({
    bShowLogo: true
  }),

  computed: {
    m_htmlAddress() {
      return this.$vuetify.breakpoint.smAndDown
        ? this.$vuetify.lang.t('$vuetify.kda.common.footer.address-mobile')
        : this.$vuetify.lang.t('$vuetify.kda.common.footer.address');
    },
    m_strQuickLinks() {
      return this.$vuetify.lang.t('$vuetify.kda.common.footer.quick-links');
    },
    m_strFindUs() {
      return this.$vuetify.lang.t('$vuetify.kda.common.footer.find-us');
    },
    m_htmlCopyright() {
      return this.$vuetify.lang.t('$vuetify.kda.common.footer.copy-right');
    },
    m_arrQuickLinks() {
      return [
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.common.menu.home'),
          strComponent: 'p',
          onClick: () => this.navToSubMain('KDAHome')
        },
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.common.menu.about'),
          strComponent: 'p',
          onClick: () => this.navToSubMain('KDAStory')
        },
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.common.menu.process'),
          strComponent: 'p',
          onClick: () => this.navToSubMain('KDAProcess')
        },
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.common.menu.solutions'),
          strComponent: 'p',
          onClick: () => this.navToSubMain('KDASolutions')
        },
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.common.menu.projects'),
          strComponent: 'p',
          onClick: () => this.navToSubMain('KDAProject')
        },
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.common.menu.blog'),
          strComponent: 'p',
          onClick: () => this.navToSubMain('KDABlog')
        }
      ];
    },
    m_arrFindUs() {
      return [
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.common.footer.facebook'),
          strComponent: 'p',
          onClick: () => this.findusclick('https://www.facebook.com/kedatech/')
        },
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.common.footer.instagram'),
          strComponent: 'p',
          onClick: () => this.findusclick('https://www.instagram.com/keda_tech/')
        },
        {
          strText: this.$vuetify.lang.t('$vuetify.kda.common.footer.linkedin'),
          strComponent: 'p',
          onClick: () => this.findusclick('https://www.linkedin.com/company/keda-tech/')
        }
      ];
    }
  },

  methods: {
    navToSubMain: function (page) {
      this.$router.push({ name: page })
    },
    quicklinksclick: function (e) {
      window.location.href = `https://www.keda-tech.com/${e}`;
    },
    findusclick: function (e) {
      window.location.href = e;
    }
  }
};
</script>

<style scoped lang="scss">
.mt-50 {
  margin-top: 50px;
}
.textCopyright {
  margin-top: 5vh;
}

.textLink {
  margin-top: 4vh;
  cursor: pointer;
}
.wrap-row-address {
  display: 'flex';
  flex-direction: 'column';
  &.mobile {
    justify-content: 'center' !important;
    align-items: 'center' !important;
  }
}
</style>
